import { Item } from '@prisma/client'
import { ListTemplateResponseDto } from '~/server/modules/list/list.util'
import { ItemsetCardContent, RecordCardContent } from './record/CardContentView'
import { ListLookupView } from './ListLookupView'
import { AugmentedItem } from '~/state/schemas/questions/utils'
import _ from 'lodash'
import { IssueWithPath } from '~/server/modules/issue/types'
import { ReminderWithStatus } from '~/features/reminder/types'

export class ListTemplateView {
  private readonly listTemplate: ListTemplateResponseDto
  constructor(listTemplate: ListTemplateResponseDto) {
    this.listTemplate = listTemplate
  }

  get id() {
    return this.listTemplate.id
  }

  get sections() {
    return this.listTemplate.sections || []
  }

  get name() {
    return this.listTemplate.name
  }

  get items() {
    return this.listTemplate.items ?? []
  }

  get parentListTemplateId() {
    return this.listTemplate.parentListTemplateId
  }

  get description() {
    return this.listTemplate.description
  }

  get otherStates() {
    return this.listTemplate.otherStates
  }

  get annotations() {
    return this.listTemplate.annotations
  }

  public getNestedSections(
    sections: ListTemplateResponseDto[] = this.sections,
  ) {
    if (!this.sections || !this.sections.length) return []
    this.sections.forEach((section) => {
      const sectionView = new ListTemplateView(section)
      const nested = sectionView.getNestedSections()
      sections = [...sections, ...nested]
    })
    return sections
  }

  public getNestedItems(items: AugmentedItem<Item>[] = this.items) {
    if (!this.sections || !this.sections.length) {
      return this.items
    }
    this.sections.forEach((section) => {
      const sectionView = new ListTemplateView(section)
      const nested = sectionView.getNestedItems()
      items = [...items, ...nested]
    })
    return items
  }

  public getItemsAndItemsetsForSection = (
    sections: string[],
    listLookupView: ListLookupView,
    issues: IssueWithPath[],
    reminders: ReminderWithStatus[],
    nested = false, // should this return all the nested itemsets & items, or just the top level
  ): {
    itemsetCards: ItemsetCardContent[]
    itemRecordCards: RecordCardContent[]
  } => {
    return {
      itemsetCards: this.getItemsets(listLookupView, issues, reminders, nested),
      itemRecordCards: this.getRecordCardContentsWithoutTags(
        listLookupView,
        issues,
        reminders,
        nested,
      ),
    }
  }

  private getItemsets = (
    listLookupView: ListLookupView,
    issues: IssueWithPath[],
    reminders: ReminderWithStatus[],
    nested: boolean,
  ): ItemsetCardContent[] => {
    const recordCards = createRecordCardContentFromDependentQuestions(
      nested ? this.getNestedItems() : this.items,
      listLookupView.latestSubmission?.recordsByItemId ?? {},
      issues,
      reminders,
    )
    return _.chain(recordCards)
      .sort(
        (a, b) =>
          (a.item.metadata.itemsetPosition ?? 0) -
          (b.item.metadata.itemsetPosition ?? 0),
      )
      .filter((recordCard) => recordCard.item.tag !== null)
      .groupBy(({ item }) => item.tag)
      .mapValues((v, k): ItemsetCardContent => {
        return {
          nestedRecords: v.sort((a, b) => {
            return (
              a.item.metadata.positionInsideParent -
              b.item.metadata.positionInsideParent
            )
          }),
          tag: k,
        }
      })
      .values()
      .value()
  }

  private getRecordCardContentsWithoutTags = (
    listLookupView: ListLookupView,
    issues: IssueWithPath[],
    reminders: ReminderWithStatus[],
    nested: boolean,
  ) => {
    const recordCards = createRecordCardContentFromDependentQuestions(
      nested ? this.getNestedItems() : this.items,
      listLookupView.latestSubmission?.recordsByItemId ?? {},
      issues,
      reminders,
    )
    return _.chain(recordCards)
      .filter((recordCard) => recordCard.item.tag === null)
      .sort(
        (a, b) =>
          a.item.metadata.positionInsideParent -
          b.item.metadata.positionInsideParent,
      )
      .value()
  }
}

/**
 * TODO: make this work with deeply nested questions
 * @param items
 * @param recordsByItemId
 * @returns
 */
export const createRecordCardContentFromDependentQuestions = (
  items: AugmentedItem<Item>[],
  recordsByItemId: { [key: string]: NonNullable<RecordCardContent['record']> },
  issues: IssueWithPath[],
  reminders: ReminderWithStatus[],
): RecordCardContent[] => {
  // mapping between {parentQs: childQs[]}
  const itemsGroupedByDependentQs = _.chain(items)
    .groupBy((item) => item.dependsOnItemId)
    .value()
  const issuesByItemId = _.chain(issues)
    .groupBy((issue) => issue.itemId)
    .value()
  const remindersByItemId = _.chain(reminders)
    .groupBy((reminder) => reminder.itemId)
    .value()
  // filter by only top-level items which have no conditionals, since these will
  return items
    .filter((item) => !item.dependsOnItemId)
    .map((item) => {
      const dependingItems = itemsGroupedByDependentQs[item.id] ?? []
      return {
        record: recordsByItemId[item.id],
        item,
        issues: issuesByItemId[item.id] ?? [],
        reminders: remindersByItemId[item.id] ?? [],
        dependentRecordCards: dependingItems.map((nestedItem) => {
          return {
            record: recordsByItemId[nestedItem.id],
            item: nestedItem,
            dependentRecordCards: [],
            issues: issuesByItemId[nestedItem.id] ?? [],
            reminders: remindersByItemId[nestedItem.id] ?? [],
          }
        }),
      }
    })
}
